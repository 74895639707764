<template>
  <div class="garden">
    <p>
      <span class="page-title">会员等级</span>
      <el-switch v-model="status" active-text="启用" inactive-text="停用" class="garden-sw"></el-switch>
    </p>
    <p class="yk-tip">用户完成任务进行成长值获取，成长值匹配不同权益等级。</p>

    <div class="garden-container">
      <div class="garden-container-opr">
        <!--        <el-button type="primary" size="small">新增会员</el-button>-->
        <el-button type="primary" plain size="small">配置降级规则</el-button>
      </div>

      <el-table
        :data="members">
        <el-table-column prop="label" label="会员等级" width="100">
          <template slot-scope="scope">
            <p class="label">
              {{scope.row.label}}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称">
          <template slot-scope="scope">
            {{scope.row.name? scope.row.name:'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="rules" label="触发条件">
          <template slot-scope="scope">
            <div class="info">
              <template v-if="scope.row.rules && scope.row.rules.length >0">
                <p v-for="(rule, idx) in scope.row.rules" :key="idx">{{rule.name}}</p>
              </template>

              <span v-else>-</span>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="right" label="会员权益">
          <template slot-scope="scope">
            <div class="info">
              <template v-if="scope.row.right && scope.row.right.length >0">
                <p v-for="(right, idx) in scope.row.right" :key="idx">{{right.name}}</p>
              </template>

              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="gift" label="升级礼包">
          <template slot-scope="scope">
            <div class="info">
              <template v-if="scope.row.gifts && scope.row.gifts.length >0">
                <p v-for="(gift, idx) in scope.row.gifts" :key="idx">{{gift.name}}</p>
              </template>

              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="会员人数" align="center">
          <template slot-scope="scope">
            {{scope.row.number? scope.row.number:'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="启用状态" align="center">
          <template slot-scope="scope">
            {{scope.row.status === 1?'启用': '停用'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click="$router.push({name: 'mkt.member.garden.detail', params:{label: scope.row.label}})">去配置
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
      let members = []
      members.push(this.members[0])
      for (let i = 2; i <= 10; i++) {
        members.push({
          label: 'VIP' + i
        })
      }
      this.members = members
    },
    data: function () {
      return {
        status: true,
        members: [
          {
            label: 'VIP1',
            name: '咖啡爱好者',
            rules: [
              {
                name: '绑定手机号',
                key: 'phone',
              },
              {
                name: '成长值达到1',
                key: 'growth',
                cmp: '=',
                value: 1
              },
            ],
            right: [
              {
                name: '会员包邮',
                key: 'send_free',
              },
              {
                name: '消费折扣',
                key: 'pay_reduce',
                value: 85,
              },
              {
                name: '积分倍率',
                key: 'growth_rate',
                value: 1.3,
              }
            ],
            gifts: [
              {
                name: '赠送1杯意式咖啡',
                key: 'coffee',
                type: 1,
                value: 1,
              }
            ],
            number: 1551,
            status: 1,
          }
        ],
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .garden {
    @include container();

    &-sw {
      margin-left: $small-space;
    }

    .label {
      color: #ffb360;
    }

    &-container {
      margin-top: 32px;

      .info {
        font-size: 12px;
      }

      &-opr {
        margin-bottom: $middle-space;
      }
    }
  }
</style>
